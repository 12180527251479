import { newArray } from "@/utils/array";
import router from "@/router";
// import route from "vue-router";

export default {
  namespaced: true,
  state: {
     // tabs的数组
     tabs: [],
     index: 0,
     path: '' // 路由路径
  },
  mutations: {
     chanegRouter(state,data) {
        state.tabs.push(data)  
        console.log(data, 'vuex');
        // 数组去重
        const list = newArray(state.tabs)
        // 拿到新数组
        state.tabs = list
        // 拿到对应索引
        state.index = list.findIndex( item =>  item.title === data.title )
        state.path = data.path
     },
       // 删除tabs中的标签
    delTabs(state, data) {
      // 防止全部删除
      if (state.tabs.length > 1) {
        state.tabs.splice(data, 1)
        
        if (data == state.index && data == '0') {
         state.index = 0
       } else if (!(data != state.index && data > state.index)) {
         state.index = state.index - 1
       }
        // 拿到最新的路由
        const path = state.tabs[state.index].path
        // 避免重复跳转
        if (router.currentRoute.path !== path) {
          router.push(path)
        }
      }
    },
     // 菜单栏获取index
     getPath(state) {
      console.log(state)
         console.log(state.tabs[state.index].path );
        state.path  = state.tabs.length > 0 ? state.tabs[state.index].path :'/'
     },
     change(state) {
      console.log('切换');
       if(state.tabs.length > 1) {
        console.log(211);
        state.tabs = [],
        state.index = 0
       }else {
        state.tabs = [],
        state.index = 0
        // state.path = '/basicInformationt'
       }
     }
  },
  actions: {

  }
}