export const zhFunction = () => {
    return  {
        
        '模式':'模式',
        // 事件类型
        '告警类型':'告警类型',
        '失败类型':'失败类型',
        '设备时间':'设备时间',
        // 上报时间
        "授权二维码显示":"授权二维码显示",
        "可选":"可选",
        "开锁统计（所有门店）":"开锁统计（所有门店）",
        "告警统计（所有门店）":"告警统计（所有门店）",
        "入住统计（所有门店）":"入住统计（所有门店）",
        "告警数":"告警数",
        "酒店":"酒店",
        "门店名称":"门店名称",
        "暂无设备":"暂无设备",
        "暂未支持该功能":"暂未支持该功能",
        "个设备":"个设备",
        "编辑员工":"编辑员工",
        "创建员工":"创建员工",
        "任务列表":"任务列表",
        "岗位描述":"岗位描述",
        "发 起 人":"发 起 人",
        "执 行 人":"执 行 人",
        "历史统计":"历史统计",
        "当前任务":"当前任务",
        "蓝牙（成功）":"蓝牙（成功）",
        "蓝牙（失败）":"蓝牙（失败）",
        "卡（成功）":"卡（成功）",
        "卡（失败）":"卡（失败）",
        "二维码（成功）":"二维码（成功）",
        "二维码（失败）":"二维码（失败）",
        "密码（成功）":"密码（成功）",
        "密码（失败）":"密码（失败）",
        "远程（成功）":"远程（成功）",
        "远程（失败）":"远程（失败）",
        "撬锁":"撬锁",
        "通道关闭":"通道关闭",
        "通道打开":"通道打开",
        "多次尝试失败":"多次尝试失败",
        "低电量":"低电量",
        "其它":"其它",
        "钥匙开门":"钥匙开门",
        "威胁开门":"威胁开门",
        "开锁统计":"开锁统计",
        "告警统计":"告警统计",
        "用户":"用户",
        "上报时间":"上报时间",
        "开关":"开关",
        "一次性密码":"一次性密码",
        "酒店管理":"酒店管理",
        "当前告警":"当前告警",
        "关键词":"关键词",
        "住客数量":"住客数量",
        "权限/事件统计":"权限/事件统计",
        "概括":"概括",
        "钥匙分享":"Key Assign (Order)",
        "钥匙分享（PMS）":"Key Assign (PMS)",
        "验证码":"Verify Code (Order)",
        "验证码（PMS）":" Verify Code (PMS)",
        "入住信息":"入住信息",
        "请选择时间": "请选择时间",
        "重置":"重置",
        "请选择付款方式":" 选择付款方式",
        "没有可购买套餐":'没有可购买套餐',
        "vw": "7vw",
        "vw2": "10vw",
        "vw3": "8vw",
        "后台管理端": "后台管理端",
        "登录": "登录",
        "修改密码": "修改密码",
        "请完整填写数据":"请完整填写数据",
        "切换成功": "SwitchingSuccessful",
        "编辑": "编辑",
        "添加":" 添加",
        "删除": "删除",
        "新密码": "新密码",
        "原密码":"原密码",
        "六位验证码": "六位验证码",
        "请选择门店": "请选择门店",
        "请输入邮箱或账号": "请输入邮箱或账号",
        "请输入密码":"请输入密码",
        "请输入验证码":"请输入验证码",
        "欢迎登录成功": "欢迎 登录成功",
        "请输入新密码":"请输入新密码",
        "请输入原密码": "请输入原密码",
        "点击切换":"点击切换",
        "获取验证码": "获取验证码",
        "立即保存": "立即保存",
        "确认删除吗":"确认删除吗",
        "删除成功":"删除成功",
        "远程设置": "远程设置",
        "关联状态": "关联状态",
        "关联设备": "关联设备",
        "设备类型": "设备类型",
        "设备状态": "设备状态",
        "绑定时间": "绑定时间",
        "解绑":"解绑",
        "操作成功":"操作成功",
        "操作失败":"操作失败",
        "数据不允许修改": "数据不允许修改",
        "暂无信息": "暂无信息",
        "是": "是",
        "否": "否",
        "操作": "操作",
        "序号": "序号",
        "确定":"确定",
        "搜索": "搜索",
        "楼栋": "楼栋",
        "楼层": "楼层",
        "请选择楼栋":"请选择楼栋",
        "请选择楼层" :"请选择楼层",
        "新增设备": "新增设备",
        "确认添加": "确认添加",
        "请选择门锁":"请选择门锁",
        "确认解除绑定吗": "确认解除绑定吗",
        "请勿重复操作":"请勿重复操作",
        "解绑成功":"解绑成功",
        "解绑失败":"解绑失败",
        "全部楼层": "全部楼层",
        "房间号":"房间号",
        "设备名称": "设备名称",
        "确认": "确认",
        "取消": "取消",
        "退出登录": "退出登录",
        "选择门店": "选择门店",
        "切换中文": "切换中文",
        "SwitchEnglish": "SwitchEnglish",
        "确定退出登录吗": "确定退出登录吗",
        "退出成功": "退出成功",
        "请先登录": "请先登录",
        "登录信息过期": "登录信息过期",
        "不允许操作": "不允许操作",
        "付款结果": "付款结果",
        "支付成功": "支付成功",
        "失败原因": "支付失败",
        "完成": "完成",
        "重新支付": "重新支付",
        "支付已取消":"支付已取消",
        "支付失败": "支付失败",
        "返回":"返回",
        "参数格式错误": "参数格式错误",
        "没有权限操作": "没有权限操作",
        "登录失败": "登录失败",
        "处理中": "处理中",
        "已取消": "已取消",
        "无数据":"无数据",
        "无效用户号":"无效用户号",
        "缺少参数": "缺少参数",
        "用户已过期": "用户已过期",
        "容量已满": "容量已满",
        "无效状态": "无效状态",
        "授权成功": "授权成功",
        "数据重复": "数据重复",
        "无实体数据": "无实体数据",
        "写入控制秘钥错误": "写入控制秘钥错误",
        "请至少选择一种开锁方式": "请至少选择一种开锁方式",
        "请选择开始或结束时间": "请选择开始或结束时间",
        "错误数据":"错误数据",
        "内部错误":"内部错误",
        "会话超时":"会话超时",
        "未授权": "未授权",
        "成功": "成功",
        "失败": "失败",
        "锁": "锁",
        "取电开关": "取电开关",
        "梯控": "梯控",
        "网关": "网关",
        "开锁记录": "开锁记录",
        "异常记录": "异常记录",
        "本地时间": "本地时间",
        "天": "天",
        "更多操作": "更多操作",
        "远程操作中": "远程操作中",
        "独立区域": "独立区域",
        "验证码错误":"验证码错误",
        "没有授权":"没有授权",
        "独立房间": "独立房间",
        "独立公共门": "独立公共门",
        "注册成功":"注册成功",
        "完成注册":"完成注册",
        "请输入酒店描述":"请输入酒店描述",
        "请选择国家时区":"请选择国家时区",
        "请选择国家地区":"请选择国家地区",
        "请输入电话号码":"请输入电话号码",
        "请输入电子邮箱":"请输入电子邮箱",
        "请输入集团名称":"请输入集团名称",
        "集团账号注册":"集团账号注册",
        "账号注册":"账号注册",
        "请注意检查邮箱信息":"请注意检查邮箱信息",
        "开锁范围":"开锁范围",
        "暂无账号":"暂无账号",
        "全部设备":"全部设备",
        "联网部署":"联网部署",
        "订单信息":"订单信息",
        "重置密码":"重置密码",
        "注册":"注册",
        入住统计:"入住统计",
        '权限/事件':"权限/事件",
        "任务统计":"任务统计",
        "数据中心":"数据中心",
        "开门活跃数据":"开门活跃数据",
        "房间状态统计":"房间状态统计",
        "房间数量":"房间数量",
        "客户房间统计":"客户房间统计",
        "任务完成时效":"任务完成时效",
        "相关员工数量":"相关员工数量",
        "任务数量":"任务数量",
        "剩余":"剩余",
        "已使用":"已使用",
        "总数":"总数",
        "名称":"名称",
        "蓝牙":"蓝牙",
        "卡":"卡",
        "二维码":"二维码",
        "密码":"密码",
        "报警数据":"报警数据",
        "套餐使用数据":"套餐使用数据",
        "设备状态统计":"设备状态统计",
        "查看详情":"查看详情",
        "空房":"空房",
        "电量不足":"电量不足",
        "已入住":"已入住",
        "维修":"维修",
        "脏房":"脏房",
        "间":"间",
        "智能客服":"智能客服",
        "未知":"未知",
        "离线":"离线",
        "在线":"在线",
        "房卡":"房卡",
        "异常设备":"异常设备",
        "取电":"取电",
        "门锁":"门锁",
        "门禁":"门禁",
        "应用":"应用",
        "邮件":"邮件",
        "短信":"短信",
        "完成数量":"完成数量",
        "员工数量":"员工数量",
        "查看任务列表":"查看任务列表",
        "结束日期":"结束日期",
        "开始日期":"开始日期",
        "至":"至",
        "本年度":"本年度",
        "本季度":"本季度",
        "本月":"本月",
        "一周":"一周",
        "当日":"当日",
        "授权":"授权",
        "A开锁":"开锁",
        "蓝牙钥匙":"蓝牙钥匙",
        "查看事件":"查看事件",
        "查看授权":"查看授权",
        "入住人数":"入住人数",
        "已入住房间":"已入住房间",
        "未入住房间":"未入住房间",
        "时间":"时间",
        "告警":"告警",
        "设备信息":"设备信息",
        "开锁类型":"开锁类型",
        "详情":"详情",
        "开锁事件":"开锁事件",
        "相关房间":"相关房间",
        "事件类型":"事件类型",
        "权限事件":"权限事件",
        "房号":"房号",
        "住客信息":"住客信息",
        "是否开反锁":"是否开反锁",
        "结束时间":"结束时间",
        "开始":"开始",
        "授权状态":"授权状态",
        "酒店名称":"酒店名称",
        "入住数":"入住数",
        "入住房间数":"入住房间数",
        "酒店入住率":"酒店入住率",
        "账户管理":"账户管理",
        "门店管理": {
            "酒店WIFI描述":"酒店WIFI描述",
            "请输入验证码":"请输入验证码",
            "设备信息":"设备信息",
            "房间数量":"房间数量",
            "入住统计": "入住统计",
            "权限/事件": "权限/事件",
            "任务统计": "任务统计",
            "账户管理": "账户管理",
            "员工管理": "员工管理",
            "员工信息": "员工信息",
            "请输入扇区号": "请输入扇区号",
            "起始扇区号": "起始扇区号",
            "登录权限": "登录权限",
            "岗位": "岗位",
            "邮箱": "邮箱",
            "电话": "电话",
            "姓名": "姓名",
            "授权管理": "授权管理",
            "开锁范围":"开锁范围",
            "确认删除该员工吗": "确认删除该员工吗",
            "请加入后缀": "请加入后缀",
            "权限分配": "权限分配",
            "岗位任务": "岗位任务",
            "岗位人数": "岗位人数",
            "岗位名称": "岗位名称",
            "有效期":"有效期",
            "开门方式":"开门方式",
            "操作人": "操作人",
            "岗位管理": "岗位管理",
            "新增员工": "新增员工",
            "请输入员工名称": "请输入员工名称",
            "请输入岗位名称": "请输入岗位名称",
            "请至少选择一个岗位": "请至少选择一个岗位",
            "请至少选择一个端口": "请至少选择一个端口",
            "请输入账号": '请输入账号',
            "请输入电话": "请输入电话",
            "请输入邮箱":"请输入邮箱",
            "新增岗位": "新增岗位",
            "集团信息": "集团信息",
            "门店列表": "门店列表",
            "管理员列表": "管理员列表",
            "请输入门店名称": "请输入门店名称",
            "请输入酒店地址": "请输入酒店地址",
            "ID": "ID",
            "门店账号": "门店账号",
            "门店信息": "门店信息",
            "全局参数": "全局参数",
            "离店时间":"离店时间",
            "开启反锁":"开启反锁",
            "发卡器": "发卡器",
            "梯控": "梯 控",
            "取电开关":"取电开关",
             "添加门店": "添加门店",
             "编辑门店": "编辑门店",
             "账号信息": "账号信息",
             "账号":"账号",
             "密码":"密码",
             "登录端口": "登录端口",
             "酒店信息":"酒店信息",
             "酒店名称":"酒店名称",
             "所在时区":"所在时区",
             "酒店地址":"酒店地址",
             "货币":"货币", 
             "请选择所在时区":"请选择所在时区",
             "请选择货币类型":"请选择货币类型",
             "请选择时间":"请选择时间",
             "请选择发卡器":"请选择发卡器",
             "请选择工作模式":"请选择工作模式",
             "酒店介绍": "酒店介绍",
             "前台电话": "前台电话",
             "WIFI账号": "WIFI账号",
             "WIFI密码": "WIFI密码",
             "基本信息": "基本信息",
            
             "请输入离店时间": "请输入离店时间",
             "请输入前台电话": "请输入前台电话",
             "请输入酒店名称": "请输入酒店名称",
             "请输入密码": "请输入密码",
             "请输入账号名称": '请输入账号名称',
             "添加成功": "添加成功",
             "编辑成功": "编辑成功",
             "制卡": "制卡",
             "扇区": "扇区",
             "制卡成功": "制卡成功",
             "制卡失败": "制卡失败",
             "扇区密钥错误":"扇区密钥错误",
             "请放置IC卡":"请放置IC卡",
             "无可用发卡器":"无可用发卡器",
             "请检查本地服务及发卡器状态":"请检查本地服务及发卡器状态",
             "确定制卡":"确定制卡",
             "取消制卡":"取消制卡",
        },
        "房间": {
            "楼栋名称":"楼栋名称",
            "楼层名称":"楼层名称",
            "添加":"添加",
            "保存":"保存",
            "房间管理": "房间管理",
            "楼栋/楼层": "楼栋/楼层",
            "房间ID":"房间ID",
            "请输入编号": "请输入编号",
            "房间类型": "房间类型",
            "房间": "房间",
            "楼栋": "楼栋",
            "楼层":"楼层",
            "绑定区域": "绑定区域",
            "添加楼栋":"添加楼栋",
            "添加楼层":"添加楼层",
            "请输入楼层或楼栋":"请输入楼层或楼栋",
            "请选择楼层或添加楼层": "请选择楼层或添加楼层",
            "楼层编号": "楼层编号",
            "逻辑楼层号":"逻辑楼层号",
            "请选择楼栋":"请选择楼栋",
            "请输入楼层编号": "请输入楼层编号",
            "逻辑楼层号已选择完毕": "逻辑楼层号已选择完毕",
            "设置房间": "设置房间",
            "添加房型": "添加房型",
            "添加房间类型":'添加房间类型',
            "编辑房间类型":"编辑房间类型",
            "创建房间类型":"创建房间类型",
            "保存房间类型":"保存房间类型",
            "请输入房间类型":"请输入房间类型",
            "是否套间":"是否套间",
            "添加房间": "添加房间",
            "请输入房间号":"请输入房间号",
            "房间号":"房间号",
            "房价":"房价",
            "公共门":"公共门",
            "关联设备":"关联设备",
            "添加子房间":"添加子房间",
            "查看子房间":"查看子房间",
            "关联状态":"关联状态",
            "子房间":"子房间",
            "批量":"批量",
            "单个":"单个",
            "暂无信息": "暂无信息",
            "名称":"名称",
            "名称前缀":"名称前缀",
            "编号":"编号",
            "请选择楼层":"请选择楼层",
            "请输入名称前缀":"亲输入名称前缀",
            "请输入名称": "请输入名称",
            "请输入开始编号":"请输入开始编号",
            "请输入结束编号": "请输入结束编号",
            "请选择房型":"请选择房型",
            "前选择所属套房":"请选择所属套房",
            "请输入房价":"请输入房价",
            "请选择公共门":"请选择公共门",
            "确认删除此类型吗":"确认删除此类型吗",
            "确认删除此房间吗": "确认删除此房间吗",
            "请选择门锁型号":"请选择门锁型号",
            "请勿重复操作": "请勿重复操作",
            "设备状态": "设备状态",
            "设备类型": "设备类型",
            "绑定时间": "绑定时间",
            "请选择设备": "请选择设备",
            "所属套房": "所属套房",
            "房型": "房型",
            "请选择房价": "请选择房价",
            "确认解除绑定吗": "确认解除绑定吗"

        },
        "公共门": {
            "公共门": "公共门",
            "公共门组":"公共门组",
            "普通公共门": "普通公共门",
            "电梯区域": "电梯区域",
            "分组管理":"分组管理",
            "添加公共门": "添加公共门",
            "公共门名称": "公共门名称",
            "电梯区域名称": "电梯区域名称",
            "新增区域": "新增区域",
            "新增分组": "新增分组",
            "分组名称":"分组名称",
            "是否基础分组": "是否基础分组",
            "基础分组": "基础分组",
            "请输入公共门名称": "请输入公共门名称",
            "请输入区域名称": "请输入区域名称",
            "请输入分组名称": "请输入分组名称",
            "门号":"门号",
            "梯控": "梯控",
            "编辑分组":'编辑分组',
            "创建分组":"创建分组",
            "创建公共门":"创建公共门",
            "编辑公共门":"编辑公共门",
            "请选择楼栋":"",
            "请选择楼层":"",
        },
        "账户": {
            "描述":"描述",
            "功能套餐":"功能套餐",
            "增值服务":"增值服务",
            "账户信息": "账户信息",
            "有效期截止": "有效期截止",
            "门店信息":"门店信息",
            "短信":"短信",
            "邮件":"邮件",
            "充值总量": "充值总量",
            "集团信息": "集团信息",
            "条": "条",
            "已使用": "已使用",
            "未使用": "未使用",
            "充值": "充值",
            "充值记录":"充值记录",
            "发送记录":"发送记录",
            "短信充值": "短信充值",
            "项目名称": "项目名称",
            "折扣":"折扣",
            "费用共计":"费用共计",
            "支付方式": "支付方式",
            "立即购买": "立即购买",
            "立即支付": "立即支付",
            "订单号": "订单号",
            "套餐名称": "套餐名称",
            "价格": "价格",
            "支付金额": "支付金额",
            "购买时间": "购买时间",
            "操作人员": "操作人员",
            "接收人姓名": "接收人姓名",
            "联系方式": "联系方式",
            "入住信息": "入住信息",
            "发送类型": "发送类型",
            "发送时间": "发送时间",
            "发送状态": "发送状态",
            "购买状态": "购买状态",
            "已创建": "已创建",
            "已推送": "已推送",
            "已付款": "已付款",
            "已取消":"已取消",
            "需退款": "需退款",
            "邮件充值":"邮件充值",
            "未知": "未知",
            "密码": "密码",
            "二维码": "二维码",
            "组合授权": "组合授权",
            "通知": "通知",
            "成功": "成功",
            "失败": "失败",
            "请选择套餐":"请选择套餐",
        },
        "设备": {
            '设备':'设备',
            "关联中":"关联中",
            "暂无数据":"暂无数据",
            "设备时钟":"设备时钟",
            "版本":"版本",
            "设备管理": "设备管理",
            "同步时钟" : "同步时钟",
            "门禁管理": "门禁管理",
            "故障时间":"故障时间",
            "未设备": "未设备",
            "未关联":"未关联",
            "方式": "方式",
            "门禁": "门禁",
            "梯控": '梯控',
            "结果": "结果",
            "电量": "电量",
            "个设备": "个设备",
            "已监控": "已监控",
            "类型选择": "类型选择",
            "修改类型": "修改类型",
            "密码与卡": "密码与卡",
            "临时密码": "临时密码",
            "在线时长": "在线时长",
            "告警": "告警",
            "全部": "全部",
            "系统卡": "系统卡",
            "用户卡": "用户卡",
            "参数卡": "参数卡",
            "总控卡":"总控卡",
            "楼层卡": "楼层卡",
            "清洁卡": "清洁卡",
            "维修卡": "维修卡",
            "职员卡": "职员卡",
            "备用卡": "备用卡",
            "时钟卡": "时钟卡",
            "会议卡": "会议卡",
            "注销卡": "注销卡",
            "终止卡": "终止卡",
            "电量不足": "电量不足",
            "明细": "明细",
            "类型": '类型',
            "当前信息": "当前信息",
            "开锁类型": "开锁类型",
            "开锁明细": "开锁明细",
            "独立模式":"独立模式",
            "酒店模式": "酒店模式",
            "营业模式": "营业模式",
            "操作人ID": "操作人ID",
            "门锁管理": "门锁管理",
            "设备类型": "设备类型",
            "请选择设备类型": "请选择设备类型",
            "请选择设备": "请选择设备",
            "选择设备": "选择设备",
            "新增设备": "新增设备",
            "网关管理": "网关管理",
            "梯控管理": "梯控管理",
            "取电开关": "取电开关",
            "同步设备状态": "同步设备状态",
            "请输入设备名称": "请输入设备名称",
            "设备信息": "设备信息",
            "电量获取时间": "电量获取时间",
            "绑定区域": "绑定区域",
            "请输入设备区域": "请输入设备区域",
            "扇区": "扇区",
            "联网详情": "联网详情",
            "关联信息": "关联信息",
            "设备名称": "设备名称",
            "设备ID": "设备ID",
            "网关": "网关",
            "更新时间": "更新时间",
            "设备绑定类型": "设备绑定类型",
            "开锁记录": "开锁记录",
            "远程开锁": "远程开锁",
            "异常记录": "异常记录",
            "检测网关": "检测网关",
            "关联失败": "关联失败",
            "正在关联":"正在关联",
            "关联成功":"关联成功",
            "信号": "信号",
            "状态": "状态",
            "时间": "时间",
            "刷新": "刷新",
            "导出": "导出",
            "选择日期时间": "选择日期时间",
            "执行失败": "执行失败",
            "指令下发失败": "指令下发失败",
            "指令超时": "指令超时",
            "无网关":"无网关",
            "无指令ID信息": "无指令ID信息",
            "执行成功": "执行成功",
            "网关状态": "网关状态",
            "历史记录": "历史记录",
            "历史在线记录": "历史在线记录",
            "查询": "查询",
            "至": "至",
            "查询成功": "查询成功",
            "网关未联网": "网关未联网",
            "所属电梯": "所属电梯",
            "继电器端口": "继电器端口",
            "自动分配端口": "自动分配端口",
            "当前状态": "当前状态",
            "远程设置": "远程设置",
            "逻辑楼层号": "逻辑楼层号", 
             "新增": "新增",
             "操作人": "操作人",
             "开锁方式": "开锁方式",
             "故障": "故障",
             "异常时间": "异常时间",
             "门锁": "门锁",
             "联网状态": "联网状态",
             "开关状态": "开关状态",
             "卡号": "卡号",
             "在线": "在线",
             "离线": "离线",
             "无效绑定":"无效绑定",
             "绑定失败":"绑定失败",
             "通电":"通电",
             "断电":"断电",
             "未配置":"未配置", 
             "历史在线时长":"历史在线时长"
        },
        "开锁": {
            "开锁权限": "开锁权限",
            "卡": "卡",
            "授权信息": "授权信息",
            "已失效": "已失效",
            "已撤销": "已撤销",
            "撤销失败": "撤销失败",
            "撤销中": "撤销中",
            "未知": "未知",
            "蓝牙钥匙": "蓝牙钥匙",
            "门禁名称": "门禁名称",
            "梯控名称": "梯控名称",
            "门锁名称": "门锁名称",
            "密码": "密码",
            "二维码": "二维码",
            "请输入持卡人": "请输入持卡人",
            "卡号": "卡号",
            "类型": "类型",
            "全部": "全部",
            "全局":"全局",
            "持有人": "持有人",
            "持卡人": "持卡人",
            "状态": "状态",
            "关键字搜索":"关键字搜索",
            "可否开反锁": '可否开反锁',
            "开锁范围": "开锁范围",
            "有效期": "有效期",
            "操作人": "操作人",
            "姓名": "姓名",
            "邮箱": "邮箱",
            "电话": "电话",
            "用户卡": "用户卡",
            "总控": "总控",
            "楼栋": "楼栋",
            "楼层": "楼层",
            "综合": "综合",
            "房卡": "房卡",
            "持卡": "持卡",
            "是": "是",
            "否": "否",
            "房号": "房号",
            "已退卡": "已退卡",
            "账号": "账号",
            "授权状态": "授权状态",
            "生效中": "生效中",
            "未撤销": "未撤销",
            "所有授权": "所有授权"
        },
        "入住": {
            "入住记录": "入住记录",
            "用户信息": "用户信息",
            "订单名称": "订单名称",
            "房间":"房间",
            "入住状态": "入住状态",
            "时间": "时间",
            "订单号": "订单号",
            "姓名": "姓名",
            "联系方式": "联系方式",
            "入住信息": "入住信息",
            "已逾期":"已逾期",
            "已强制退房":"已强制退房",
            "入住时间": "入住时间",
            "离店时间": "离店时间",
            "退房时间": "退房时间",
            "公共门": "公共门",
            "开锁权限": "开锁权限",
            "栋": "栋",
            "层": "F",
            "导出": "导出",
            "正常": "正常",
            "预离": "预离",
            "退房处理中":"退房处理中",
            "退房处理失败": "退房处理失败",
            "已退房": "已退房",
            "已失效": "已失效",
            "查看详情": "查看详情",
            "请选择日期时间": "请选择日期时间",
         },
         "任务": {
            "任务完成时效":"任务完成时效",
            "任务列表": "任务列表",
            "客户服务": "客户服务",
            "请输入房间名称": "请输入房间名称",
            "任务类型": "任务类型",
            "酒店名称":"酒店名称",
            "发起人": "发起人",
            "发起时间":"发起时间",
            "执行人":"执行人",
            "执行时间": "执行时间",
            "完成时间": "完成时间",
            "清洁":"清洁",
            "维修":"维修",
            "任务状态": "任务状态",
            "任务单号": "任务单号",
           
            "已发起": "已发起",
            "已终止": "已终止",
            "执行中": "执行中",
            "再次发起": "再次发起",
            "已完成": "已完成",
            "请输入任务单号": "请输入任务单号",
            "房间名称": "房间名称",
            "确定":"确定",
            
        }

    } 
  }