export  const enFunction = () => {
        return {
            
            '模式':'Mode',
            // 事件类型
            '告警类型':'Alarm Detail',
            '失败类型':'Failure Detail',
            '设备时间':'Device Time',
            // 上报时间
            "授权二维码显示":"Show QR Code for Auth.",
            "可选":"Optional",
            "开锁统计（所有门店）":"Unlock Stats (All Outlets)",
            "告警统计（所有门店）":"Alarm Stats (All Outlets)",
            "入住统计（所有门店）":"Check-in Stats (All Outlets)",
            "告警数":"Alarm Count",
            "酒店":"Hotel",
            "门店名称":"Store Name",
            "暂无设备":"No Floor Info",
            "暂未支持该功能":"Feature not ready.",	
            "个设备":"Device",
            "编辑员工":"Edit Staff",
            "创建员工":"Create New Staff",
            "任务列表":"Hotel Tasks",
            "岗位描述":"Description",
            "发 起 人":"Initiator",
            "执 行 人":"Performer",
            "历史统计":"History Task Stats",
            "当前任务":"Current Tasks",
            "蓝牙（成功）":"Bluetooth (Success)",
            "蓝牙（失败）":"Bluetooth (Failure)",
            "卡（成功）":"Card (Success)",
            "卡（失败）":"Card (Failure)",
            "二维码（成功）":"QR Code (Success)",
            "二维码（失败）":"QR Code (Failure)",
            "密码（成功）":"Password (Success)",
            "密码（失败）":"Password (Failure)",
            "远程（成功）":"Remote (Success)",
            "远程（失败）":"Remote (Failure)",
            "撬锁":"Break-in",
            "通道关闭":"Door Close",
            "通道打开":"Door Open",
            "多次尝试失败":"Locked",
            "低电量":"Low Power",
            "其它":"Other",
            "钥匙开门":"Physical Key",
            "威胁开门":"Threat",
            "开锁统计":"Unlock Stats ",
            "告警统计":"Alarm Stats",
            "用户":"Guest",
            "上报时间":"Report Time",
            "开关":"Button",
            "一次性密码":"OneTime Pin",
            "酒店管理":"Hotel",
            "当前告警":'Alarms',
            "关键词":"Key Words",
            "住客数量":"Guests",
            "权限/事件统计":'Auth/Event Stats',
            "概括":"Summary",
            "钥匙分享":"Key Assign (Order)",
            "钥匙分享（PMS）":"Key Assign (PMS)",
            "验证码":"Verify Code (Order)",
            "验证码（PMS）":" Verify Code (PMS)",
            "入住信息":'Order Link',
            "请选择时间": "Please select time",
            "重置":"Default",
            " 请选择付款方式":"Please choose a pay method.",
            "没有可购买套餐":'No packages available for purchase.',
            "vw": "9vw",
            "vw2": "10vw",
            "vw3": "10vw",
            "后台管理端": "Management Console",
            "修改密码": "Change password",
            "请完整填写数据":"Please fill in the data completely",
            "登录": "Login",
            "新密码": "New password",
            "原密码":"Old password",
            "获取验证码":"Get VeriCode",
            "六位验证码": "Six-digit verification code",
            "切换成功": "Successfully Changed",
            "请输入邮箱或账号": "Please input account",
            "请输入密码":"Please input password",
            "请输入新密码":"Please enter your new password",
            "请输入原密码": "Please enter the old password",
            "请输入验证码":"Please input verfication code",
            "请选择门店": "Please select a store",
            "不允许操作": "Disallowed operation",
            "欢迎登录成功": "Welcome",
            "点击切换":"Click to change",
            "添加":"Add",
            "编辑": "Edit",
            "删除": "Delete",
            "成功" :"Already",
            "确认删除吗":"Confirm to delete?",
            "删除成功":"Already Deleted",
            "授权成功": "Authorization succeeds",
            "是": "Yes",
            "否": "No",
            "序号": "SerialNumber",
            "操作": "Operation",
            "立即保存":"Save",
            "操作成功":"Operation Success",
            "操作失败":"Operation Failure",
            "数据不允许修改": "Not allowed to change",
            "远程设置": "Remote Config",
            "关联状态": "Binding Status",
            "关联设备": "Bound Device",
            "设备类型": "Device Type",
            "设备状态": "Device Status",
            "绑定时间": "Bound Time",
            "解绑":"Unbind",
            "搜索": "Search",
            "楼栋": "Buildings",
            "楼层": "Floors",
            "请选择楼栋":"Please select building",
            "请选择楼层" :"Please select floor",
            "暂无信息": "No info",
            "新增设备": "Add New Device",
            "确认添加": "Confirm to add",
            "请选择门锁":"Please select lock",
            "确认解除绑定吗": "Continue to unbind?",
            "请勿重复操作":"Please do not repeat operation",
            "解绑成功":"Unbinding successfully",
            "解绑失败":"Unbinding failed",
            "全部楼层": "All floors",
            "房间号":"Room Number",
            "设备名称": "Device Name",
            "取消": "Cancel",
            "退出登录": "Logout",
            "选择门店": "Switch Account",
            "切换中文": "Switch Language",
            "SwitchEnglish": "SwitchEnglish",
            "确定退出登录吗": "Confirm to logout?",
            "退出成功": "Already Logout",
            "付款结果": "Payment Result",
            "支付成功": "Already Paid",
            "失败原因": "Reason of Failure",
            "完成": "Completed",
            "重新支付": "Payment Retry",
            "确定": "OK",
            "确认": "Confirm",
            "失败": "Failure",
            "支付已取消":"Payment Cancelled",
            "支付失败": "Payment Failed",
            "返回":"Return",
            "参数格式错误": "Wrong Parameter Format",
            "没有权限操作": "Not allowed to operate",
            "写入控制秘钥错误": "Error writing control key",
            "登录失败": "Login Failed",
            "处理中": "Processing",
            "已取消": "Already cancelled",
            "无数据":"No data",
            "无效用户号":"Invalid User No.",
            "缺少参数": "Missed parameter",
            "用户已过期": "User Expired",
            "容量已满": "No capacity",
            "无效状态": "Invalid state",
            "数据重复": "Duplicated data",
            "无实体数据": "No entity data",
            "错误数据":"Wrong data",
            "内部错误":"Internal Error",
            "会话超时":"Session Timeout",
            "未授权": "No authorization",
            "锁": "Lock",
            "取电开关": "Powerswitch",
            "梯控": "Elevator Controller",
            "网关": "Gateway",
            "开锁记录": "Unlock Records",
            "异常记录": 'Alarm Records',
            "本地时间": "Local time",
            "天": "Day",
            "更多操作": "More operations",
            "远程操作中": "In remote operation",
            "独立区域": "Independent region",
            "验证码错误":"Verification code error",
            "没有授权":"Not authorized",
            "独立房间": "Separate room",
            "独立公共门": "Separate Public Door",
            "注册成功":"Succeed to register.",
            "完成注册":"Submit",
            "请输入酒店描述":"Description",
            "请选择国家时区":"Time Zone",
            "请选择国家地区":"Country or Region",
            "请输入电话号码":"Phone",
            "请输入电子邮箱":"E-mail",
            "请输入集团名称":"Name of Hotel Group",
            "集团账号注册":"Register Hotel Group",
            "账号注册":"Register",
            "请注意检查邮箱信息":"Please review email later for our response.",
            "开锁范围":"Unlocking range",
            "暂无账号":"No account",
            "全部设备":"All",
            "联网部署":"Online Deployment",
            "订单信息":"Order info",
            "重置密码":"Reset password",
            "注册":"Sign in",
           "入住统计":"Check-in Stats",
            "权限/事件":"Auth/Event Stats",
            "任务统计":"Hotel Task Stats",
            "数据中心":"Data Center",
            "开门活跃数据":"Unlock Statistics",
            "房间状态统计":"Room Statistics",
            "房间数量":"Room Quantity",
            "客户房间统计":"Guest Room Statistics ",
            "任务完成时效":"Hotel Task Statistics",
            "相关员工数量":"Involved Staffs",
            "任务数量":"Task Count",
            "剩余":"Remained",
            "已使用":"Used",
            "总数":"Total",
            "名称":"Name",
            "蓝牙":"Bluetooth",
            "卡":"Card",
            "二维码":"QR Code",
            "密码":"Pin",
            "报警数据":"Alarm Statistics",
            "套餐使用数据":"Quota Info",
            "设备状态统计":"Device Status",
            "查看详情":"Details",
            "空房":"Vacant",
            "电量不足":"Battery Low",
            "已入住":"Occupied",
            "维修":"Maintenance",
            "脏房":"Dirty",
            "间":"Rooms",
            "智能客服":"Intelligent Agent",
            "未知":"Unknow",
            "离线":"Offline",
            "在线":"Online",
            "房卡":"Card",
            "异常设备":"Abnormal Device",
            "取电":"Power Switch",
            "门锁":"Lock",
            "门禁":"Access Controller",
            "应用":"App",
            "邮件":"Email",
            "短信":"SMS",
            "完成数量":"Completed",
            "员工数量":"Staffs",
            "查看任务列表":"Task List",
            "结束日期":"Start",
            "开始日期":"End",
            "至":"to",
            "本年度":"Current Year",
            "本季度":"Current Season",
            "本月":"Current Month",
            "一周":"Current Week",
            "当日":"Today",
            "授权":"Auth",
            "A开锁":"Lock Opening",
            "蓝牙钥匙":"Bluetooth Key",
            "查看事件":"Events",
            "查看授权":"Authorization",
            "入住人数":"Check-ins",
            "已入住房间":"Occupied Rooms",
            "未入住房间":"Vacant Rooms",
            "时间":"Time",
            "告警":"Alarm",
            "设备信息":"Device",
            "开锁类型":"Unlock Type",
            "详情":"Details",
            "开锁事件":"Unlock Event",
            "相关房间":"Room",
            "事件类型":"Event Detail",
            "权限事件":"Auth Event",
            "房号":"Room NO.",
            "住客信息":"Guest Info",
            "是否开反锁":"Bolted Override",
            "结束时间":"Ened time",
            "开始":"Start",
            "授权状态":"Auth Status",
            "酒店名称":"Hotel Name",
            "入住数":"Guests",
            "入住房间数":"Occupied Rooms",
            "酒店入住率":"Occupancy Rate",
            "账户管理":"Hotels & Staffs",
            "门店管理": {
                "酒店WIFI描述":"Wi-Fi Description",
                "请输入验证码":"Verification Code",
                "设备信息":"Device Info",
                "房间数量":"Rooms",
                "入住统计":"Check-in Stats",
                "权限/事件":"Auth/Event Stats",
                "任务统计":"Task Stats",
                "账户管理": "Account",
                "员工管理": "Staffs",
                "员工信息": "Staffs",
                "请输入扇区号": "Please enter the sector number",
                "起始扇区号": "Start Sector Number",
                "岗位": "Role",
                "邮箱": "Mail",
                "电话": "Phone",
                "姓名": "Name",
                "授权管理": "Authorization management",
                "开锁范围":"Unlocking range",
                "有效期":"Validity",
                "开门方式":"Opening mode",
                "登录权限": "Login permission",
                "请至少选择一个岗位": "Please select at least one role.",
                "请加入后缀": " Please use format: XXX",
                "确认删除该员工吗": "Are you sure to delete this employee",
                "权限分配": "Permission allocation",
                "岗位任务": "Post task",
                "岗位人数": "Staff Number",
                "岗位名称": "Job Role",
                "请输入账号": 'Please enter your account number',
                "请输入电话": "Please enter phone number",
                "请输入邮箱":"Please enter email",
                "登录端口": "Login port",
                "请至少选择一个端口": "Please select at least one port",
                "请至少选择一种开锁方式": "Please select at least one unlocking method",
                "请选择开始或结束时间": "Please select a start or end time",
                "操作人": "Operator",
                "岗位管理": "Roles",
                "新增员工": "Create",
                "请输入员工名称": "Please enter employee name",
                "请输入岗位名称": "Please enter the job title",
                "新增岗位": "Create",
                "集团信息": "Groug Info",
                "门店列表": "Hotel List",
                "管理员列表": "Admin List",
                "请输入门店名称": "Please input hotel name",
                "ID": "ID",
                "门店账号": "Hotel account",
                "门店信息": "Hotel info",
                "全局参数": "Global parameter",
                "离店时间":"Departure time",
                "开启反锁":"Guest Override Bolted",
                "发卡器": "Card reader",
                "梯控": "Elevator Control",
                "取电开关":"Power switch",
                 "添加门店": "Add Hotel",
                 "编辑门店": "Edit Hotel",
                 "账号信息": "Accont Info",
                 "账号":"Account",
                 "密码":"Password",
                 "酒店信息":"Hotel Info",
                 "酒店名称":"Hotel Name",
                 "所在时区":"Timezone",
                 "酒店地址":"Address",
                 "货币":"Currency", 
                 "请选择所在时区":"Please select timezone",
                 "请选择货币类型":"Please select currency",
                 "请选择时间":"Please select time",
                 "请选择发卡器":"Please select card reader",
                 "请选择工作模式":"Please select work mode",
                 "酒店介绍": "Hotel Introduction",
                 "前台电话": "Reception Tel.",
                 "WIFI账号": "WIFI Name",
                 "WIFI密码": "WIFI Password",
                 "基本信息": "Basic Info",
                 "请输入酒店地址": "Please enter the hotel address",
                 "请输入离店时间": "Please enter your departure time",
                 "请输入前台电话": "Please enter the reception phone number",
                 "请输入酒店名称": "Please enter the hotel name",
                 "请输入密码": "Please enter password",
                 "请输入账号名称": 'Please enter the account name',
                 "添加成功": "Add successfully",
                 "编辑成功": "Edit successfully",
                 "制卡": "Card Initialization",
                 "扇区": "Sector",
                 "制卡成功": "Business card printing successful",
                 "制卡失败": "Business card printing failed",
                 "扇区密钥错误":"Wrong sector key",
                 "请放置IC卡":"Please place card",
                 "无可用发卡器":"No card reader available",
                 "请检查本地服务及发卡器状态":"Please check card reader device",
                 "确定制卡":"Init Card",
                 "取消制卡":"Cancel"
            },
            "房间": {
                "楼栋名称":"Building Name",
                "楼层名称":"Floor Name",
                "添加":"Create",
                "保存":"Update",
                "楼栋": "Buildings",
                "房间ID":"Room ID",
                "楼层": "Floors",
                "请输入编号": "Please enter the number",
                "房间管理": "Rooms",
                "房间类型": "Room Type",
                "房间": "Rooms",
                "添加楼栋":"Create Building",
                "添加楼层":"Create Floor",
                "请输入楼层或楼栋":"Please input building or floor",
                "楼层编号": "Floor Sequence Number",
                "请输入楼层编号": "Select",
                "请选择楼栋":"Please select building",
                "逻辑楼层号已选择完毕": "Floor no.selected",
                "请选择楼层或添加楼层": "Please select a floor or add a floor",
                "添加房型": "Create",
                "添加房间类型":'Create Room Type',
                "编辑房间类型":"Edit Room Type",
                "创建房间类型":"Create",
                "保存房间类型":"Update",
                "请输入房间类型":"Please input room type",
                "是否套间":"Is Suite?",
                "设置房间": "Set up a room",
                "请输入房间号":"Please input room number",
                "房间号":"Room Name",
                "房价":"Room price",
                "公共门":"Common Door",
                "关联设备":"Binding Info",
                "添加子房间":"Add subroom",
                "查看子房间":"Subrooms",
                "关联状态":"Binding Status",
                "子房间":"Subroom",
                "房型": "Room type",
                "批量":"Batch",
                "单个":"Single",
                "名称":"Name",
                "名称前缀":"Name prefix",
                "暂无信息": "No Info",
                "添加房间": "Create Room",
                "绑定区域": "Binding region",
                "编号":"No.",
                "请选择楼层":"Please select floor",
                "请输入名称前缀":"Please input name prefix",
                "请输入名称": "Please input name",
                "请输入开始编号":"Please input start no.",
                "请输入结束编号": "Please input end no.",
                "请选择房型":"Please select room type",
                "前选择所属套房":"Please select suite",
                "请输入房价":"Please input room price",
                "请选择公共门":"Please select common door",
                "确认删除此类型吗": "Confirm to delete the type?",
                "确认删除此房间吗": "Confirm to delete the room?",
                "请选择门锁型号":"Please select lock type",
                "请勿重复操作" : "Please don't repeat operation",
                "所属套房": "Suite",
                "设备类型": "Device type",
                "设备状态": "Device Status",
                "绑定时间": "Bound time",
                "确认解除绑定吗":"Confirm to unbind?",
                "请选择房价": "Please select price"
            },
            "公共门": {
                "公共门": "Common Doors",
                "公共门组":"Common Door Groups",
                "普通公共门": "Common Doors",
                "电梯区域": "Elevator Areas",
                "分组管理":"Group Management",
                "添加公共门": "Add common door",
                "公共门名称": "Common Door Name",
                "电梯区域名称": "Elevator Area Name",
                "新增区域": "Add region",
                "新增分组": "Add group",
                "分组名称":"Group Name",
                "是否基础分组": "Basic Group",
                "基础分组": "Basic Group",
                "请输入公共门名称": "Please input common door name.",
                "请输入区域名称": "Please input region name",
                "请输入分组名称": "Please input group name",
                "门号":"Door number",
                "梯控": "Ladder control",
                "编辑分组":'Edit Common Door Group',
                "创建分组":"Create Common Door Group",
                "创建公共门":"Create Common Door",
                "编辑公共门":"Edit Common Door",
                "请选择楼栋":"Select",
                "请选择楼层":"Select",
            },
            "账户": {
                "描述":"Description",
                "功能套餐":"Feature Packages",
                "增值服务":"Services",
                "账户信息": "Accont info",
                "有效期截止": "Valid to",
                "门店信息":"Features",
                "短信":"Short message",
                "邮件":"Email",
                "充值总量": "Total",
                "条": "Pieces",
                "购买状态": "Purchase status",
                "集团信息":"Group info",
                "已使用": "Used",
                "未使用": "Available",
                "充值": "Tollup",
                "充值记录":"Tollup Record",
                "发送记录":"Sent Record",
                "短信充值": "Short message tollup",
                "项目名称": "Package",
                "折扣":"Discount",
                "费用共计":"Total",
                "支付方式": "Pay by",
                "立即购买": "Purchase now",
                "立即支付": "Pay now",
                "订单号": "Order No.",
                "套餐名称": "Package name",
                "价格": "Price",
                "支付金额": "Amount",
                "购买时间": "Purchase Time",
                "操作人员": "Operator",
                "接收人姓名": "Receiver",
                "联系方式": "Contact",
                "入住信息": "Checkin Info",
                "发送类型": "Sent type",
                "发送时间": "Sent time",
                "发送状态": "Sent status",
                "已创建": "Created",
                "已推送": "Sent",
                "已付款": "Paid",
                "已取消":"Cancelled",
                "需退款": "Refund Needed",
                "未知": "Unknow",
                 "密码": "Password",
                 "二维码": "QR code",
                 "组合授权": "Composite Authorization",
                 "通知": "Notification",
                "成功": "Success",
                "失败": "Failure",
                "邮件充值":"Email tollup",
                "请选择套餐":"Please choose a package.",
            },
            "设备": {
                '设备':'Device',
                "关联中":"Binding",
                "暂无数据":"No Data",
                "设备时钟":"Clock",
                "版本":"Version",
                "设备管理": "Devices",
                "门禁管理": "Access Controllers",
                "无设备": "No equipment",
                "未关联":"Not Bound",
                "同步时钟" : "Sync Time",
                "方式": "Method",
                "结果": "Result",
                "电量": "Battery",
                "个设备": "Devices",
                "已监控": "Monitoring",
                "密码与卡": "Pin and card",
                "临时密码": "Pin",
                "修改类型": "Change Type",
                "类型选择": "Type Selection:",
                "在线时长": "Online period",
                "告警": "Alarm",
                "全部": "All",
                "系统卡": "System card",
                "用户卡": "Guest card",
                "参数卡": "Parameter card",
                "总控卡":"Master card",
                "楼层卡": "Floor card",
                "清洁卡": "Housekeeping card",
                "维修卡": "Maintenance card",
                "职员卡": "Staff card",
                "备用卡": "Spare card",
                "时钟卡": "Oclock card",
                "会议卡": "Meeting card",
                "注销卡": "Erase card",
                "终止卡": "Inhabit card",
                "电量不足": "Battery low",
                "明细": "Detail",
                "类型": 'Type',
                "开锁类型": "Entry type",
                "开锁明细": "Entry detail",
                "当前信息": "Current Info",
                "独立模式":"Standalone Mode",
                "酒店模式": "Hotel Mode",
                "营业模式": "Business Mode",
                "操作人ID": "Operator ID",
                "故障时间": "Fault time",
                "门禁": "Access Controller",
                "梯控": 'Elevator Controller',
                "新增设备": "Add device",
                "设备类型": "Device type",
                "请选择设备类型": "Please select device type",
                "请选择设备": "Please select device",
                "选择设备": "Device selection",
                "门锁管理": "Locks",
                "网关管理": "Gateways",
                "梯控管理": "Elevator Controllers",
                "取电开关": "Power Switches",
                "同步设备状态": "Device synchronization status",
                "请输入设备名称": "Please input device name",
                "设备信息": "Device info",
                "电量获取时间": "Battery",
                "扇区": "Sector",
                "联网详情": "Network Detail",
                "关联信息": "Binding Info",
                "设备名称": "Device Name:",
                "设备ID": "Device ID",
                "网关": "Gateway",
                "绑定区域": "Binding region",
                "请输入设备区域": 'Please enter the device area',
                "关联失败": "Binding failed",
                "正在关联":"Binding",
                "关联成功":"Bound",
                "更新时间": "Update time",
                "设备绑定类型": "Device binding type",
                "开锁记录": "Unlock Records",
                "远程开锁": "Remote",
                "异常记录": "Alarms",
                "检测网关": "Monitoring gateway",
                "信号": "Signal",
                "状态": "Status",
                "时间": "Time",
                "刷新": "Refresh",
                "导出": "Export",
                "选择日期时间": "Select datetime",
                "执行失败": "Execution Failed",
                "指令下发失败": "Instruction sending failed",
                "指令超时": "Instruction timeout",
                "无网关":"No gateway",
                "无指令ID信息": "No instruction ID info",
                "执行成功": "Execution Success",
                "网关状态": "Gateway Status",
                "历史在线记录": "Historical online records",
                "查询": "Query",
                "历史记录": "Online History",
                "至": "to",
                "查询成功": "Query Success",
                "网关未联网": "Gateway offline",
                "所属电梯": "Elevator belong",
                "继电器端口": "Relay Ports",
                "自动分配端口": "Port auto-assigned",
                "当前状态": "Current state",
                "远程设置": "Remote config",
                "逻辑楼层号": "Logic floor no.",
                "新增": "Add",
                "操作人": "Operator",
                "开锁方式": "Unlock method",
                "故障": "Fault",
                "异常时间": "Alarm time",
                "门锁": "Lock",
                "联网状态": "Online Status",
                "开关状态": "Switch status",
                "卡号": "Card no.",
                "请先登录": "Please login",
                "登录信息过期": "Login session expired",
                "在线": "Online",
                "离线": "Offline",
                "无效绑定":"Invalid-Bound",
                "绑定失败":"Binding Failure",
                "通电":"On",
                "断电":"Off",
                "未配置":"No Config", 
                "历史在线时长":"Online Duration"         
            },
            "开锁": {
                "开锁权限": "Unlock Authorization",
                "卡": "Card",
                "授权信息": "Authorization Info",
                "已失效": "Invalid",
                "已撤销": "Withrawn",
                "撤销失败": "Withdraw Failed",
                "撤销中": "Withdrawing",
                "未知": "Unknow",
                "门禁名称": "Access control name",
                "门锁名称": "Lock name",
                "梯控名称": "Elevator Control name",
                "蓝牙钥匙": "Bluetooth Key",
                "密码": "Pin",
                "全局":"Global",
                "房卡": "Card",
                "关键字搜索":"Keyword search",
                "二维码": "QR code",
                "请输入持卡人": "Please input card holder",
                "卡号": "Card no.",
                "类型": "Type",
                "全部": "All",
                "持有人": "Card holder",
                "持卡人": "Card holder",
                "状态": "status",
                "可否开反锁": 'Override deadbolt?',
                "开锁范围": "Scope",
                "有效期": "Valid period",
                "操作人": "Operator",
                "姓名": "Name",
                "邮箱": "Email",
                "电话": "Tel.",
                "用户卡": "Guest card",
                "总控": "Master",
                "楼栋": "Building",
                "楼层": "Floor",
                "综合": "Composite",
                "持卡": "Card holding",
                "是": "Yes",
                "否": "No",
                "房号": "Room No.",
                "已退卡": "Card returned",
                "账号": "Account",
                "授权状态": "Authorization Status",
                "生效中": "Valid",
                "未撤销": "Not withdrawn",
                "所有授权": "All authorization"
            },
            "入住": {
               "入住记录": "Checkin Record",
               "用户信息": "Guest Info",
               "房间":"Room",
               "入住状态": "Room Status",
               "时间": "Time",
               "订单号": "Order No.",
               "订单名称": "Order name",
               "姓名": "Name",
               "联系方式": "Contact",
               "入住信息": "Checkin info",
               "已逾期":"Overdue",
               "已强制退房":"Forced check-out",
               "入住时间": "Arrival time",
               "离店时间": "Check-out Time",
               "退房时间": "Checkout time",
               "公共门": "Common door",
               "开锁权限": "Unlock authorization",
               "栋": "B",
               "层": "F",
               "导出": "Export",
                "正常": "Normal",
                "预离": "Plan to leave",
                "退房处理失败": "Checkout failed",
                "退房处理中":"Checkout processing",
                "已退房": "Checkout",
                "已失效": "Invalid",
                "查看详情": "View details",
                "请选择日期时间": "Please select datetime",
            },
            "任务": {
                "任务完成时效":"Minutes/Task",
                "任务列表": "Task list",
                "客户服务": "Guest Service",
                "请输入房间名称": "Key Words",
                "任务类型": "Task Type",
                "酒店名称":"Hotel Name",
                "发起人": "Initiator",
                "发起时间":"Initiation time",
                "执行人":"Executor",
                "执行时间": "Execution time",
                "完成时间": "Completion time",
                "清洁":"Cleaning",
                "维修":"Maintenance",
                "任务状态": "Task Status",
                "任务单号": "Task No.",
                "已发起": "Initiated",
                "已终止": "End",
                "执行中": "Processing",
                "再次发起": "Initiated again",
                "已完成": "Completed",
                "请输入任务单号": "Please input task no.",
                 "房间名称": "Room name",
                "确定":"Confirm",
                
            }
    }

}