import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'

Vue.use(VueRouter)

import { translate } from "../locales/index"

const routes = [
  {
    path: '/login',
    name: 'userLogin',
    component: () => import('../views/login/index')
  },
  {
    path: '*',
    name: 'FourPage',
    component: () => import('../views/404Page/index')
  },
  {
    path: '/',
    name: 'layout',
    // redirect: '/DeskBoard',  // 默认进入
    component: () => import('../views/layout/index'),
    children: [
      
      {
        path: '/DashBoard',  //门店-数据中心
        name: 'DashBoard',
        meta: { title: translate('数据中心') },
        component: () => import('../views/pages/DashBoard/index')
      },
      {
        path: '/Group_DeskBoard',  // 集团-数据中心
        name: 'Group_DeskBoard',
        meta: { title: translate('数据中心') },
        component: () => import('../views/pages/Group_DeskBoard/index')
        
      },
      {
        path: '/TaskInfo',  // 任务完成时效
        name: 'TaskInfo',
        meta: { title: translate('数据中心') },
        component: () => import('../views/pages/TaskInfo/index.vue')
      },
      {
        path: '/HotelTasks',  //门店-任务统计
        name: 'HotelTasks',
        meta: { title: translate('任务统计') },
        component: () => import('../views/pages/HotelTasks/index')
      },
      {
        path: '/Group_HotelTasks',  //集团-任务统计
        name: 'Group_HotelTasks',
        meta: { title: translate('任务统计') },
        component: () => import('../views/pages/Group_HotelTasks/index')
      },
      
      {
        path: '/AuthorizationEvents',  //门店-权限/事件
        name: 'AuthorizationEvents',
        meta: { title: translate('开锁统计') },
        component: () => import('../views/pages/AuthorizationEvents/index')
      }, 
      {
        path: '/Group_AlarmStatistics',  //告警统计
        name: 'Group_AlarmStatistics',
        meta: { title: translate('告警统计') },
        component: () => import('../views/pages/Group_AlarmStatistics/index')
      }, 
      {
        path: '/AlarmStatistics',  //集团-告警统计
        name: 'AlarmStatistics',
        meta: { title: translate('告警统计') },
        component: () => import('../views/pages/AlarmStatistics/index')
      }, 
      {
        path: '/Group_AuthorizationEvents',  //集团-权限/事件
        name: 'Group_AuthorizationEvents',
        meta: { title: translate('开锁统计') },
        component: () => import('../views/pages/Group_AuthorizationEvents/index')
      },

      {
        path: '/CheckInRecord',  //门店-入住统计
        name: 'CheckInRecord',
        meta: { title: translate('入住统计') },
        component: () => import('../views/pages/CheckInRecord/index')
      }, {
        path: '/Group_CheckInRecord',  //集团-入住统计
        name: 'Group_CheckInRecord',
        meta: { title: translate('入住统计') },
        component: () => import('../views/pages/Group_CheckInRecord/index')
      },
      {
        path: '/hotelTaskList',  //任务列表
        name: 'hotelTaskList',
        meta: { title: translate('任务统计') },
        component: () => import('../views/pages/hotelTaskList/index')
      },
      {
        path: '/CheckInList',  // 入住列表
        name: 'CheckInList',
        meta: { title: translate('入住统计') },
        component: () => import('../views/pages/CheckInList/index')
      }, {
        path: '/AuthEventsList',  //事件列表
        name: 'AuthEventsList',
        meta: { title: translate('权限/事件') },
        component: () => import('../views/pages/AuthEventsList/index')
      },
      {
        path: '/AuthList',  //权限列表
        name: 'AuthList',
        meta: { title: translate('权限/事件') },
        component: () => import('../views/pages/AuthList/index')
      },

      {
        path: '/basicInformationt',  // 基本信息
        name: 'basicInformationt',
        meta: { title: translate('门店管理.基本信息') },
        component: () => import('../views/pages/basicInformationt/index')
      },
      {
        path: '/storeList',  // 门店列表
        name: 'storeList',
        meta: { title: translate('门店管理.门店列表') },
        component: () => import('../views/pages/storeList/index')
      },
      {
        path: '/adminManagement', // 员工列表
        name: 'AdminManagement',
        meta: { title:  translate('门店管理.员工管理')},
        component: () => import('../views/pages/AdminManagement/index')
      },
      {
        path: '/jobManagement', // 岗位管理
        name: 'jobManagement',
        meta: { title:  translate('门店管理.岗位管理')},
        component: () => import('../views/pages/jobManagement/index')
      },
      // 房间管理
      {
        path: '/Building', // 楼栋/楼层
        name: 'Building',
        meta: { title: translate('楼栋') + '/' + translate('楼层') },
        component: () => import('../views/pages/Building/index')
      },
      {
        path: '/Room', // 房间
        name: 'Room',
        meta: { title: translate('房间.房间') },
        component: () => import('../views/pages/Room/index')
      },
      // 公共门
      {
        path: '/PublicDoors', // 普通公众门
        name: 'PublicDoors',
        meta: { title: translate('公共门.普通公共门') },
        component: () => import('../views/pages/PublicDoors/index')
      },
      {
        path: '/Elevator', // 电梯区域
        name: 'Elevator',
        meta: { title: translate('公共门.电梯区域') },
        component: () => import('../views/pages/Elevator/index')
      },
      {
        path: '/Grouping', // 分组管理
        name: 'Grouping',
        meta: { title: translate('公共门.分组管理') },
        component: () => import('../views/pages/Grouping/index')
      },
      // 账户信息
      {
        path: '/AccountInformation', 
        name: 'AccountInformation',
        meta: { title: translate('账户.增值服务') },
        component: () => import('../views/pages/AccountInformation/index')
      },
      // 设备采购
      {
        path: '/EquipmentList', // 设备列表
        name: 'EquipmentList',
        meta: { title: ('设备列表') },
        component: () => import('../views/pages/EquipmentList/index')
      },
      {
        path: '/OrderForm', // 订单管理
        name: 'OrderForm',
        meta: { title: '订单管理' },
        component: () => import('../views/pages/OrderForm/index')
      },
      {
        path: '/HarvestManagement', // 收获地址
        name: 'HarvestManagement',
        meta: { title: '收货地址' },
        component: () => import('../views/pages/HarvestManagement/index')
      },
       //  设备管理
       {
        path: '/allDevice', //  全部设备
        name: 'allDevice',
        meta: { title: translate('全部设备') },
        component: () => import('../views/pages/allDevice/index')
      },
      {
        path: '/LockManagement', //  门锁管理
        name: 'LockManagement',
        meta: { title: translate('设备.门锁管理') },
        component: () => import('../views/pages/LockManagement/index')
      },
      
      {
        path: '/AccessControl', //  门禁管理
        name: 'AccessControl',
        meta: { title: translate('设备.门禁管理') },
        component: () => import('../views/pages/AccessControl/index')
      },
      {
        path: '/GatewayAdministration', //  网关管理
        name: 'GatewayAdministration',
        meta: { title: translate('设备.网关管理') },
        component: () => import('../views/pages/GatewayAdministration/index')
      },
      {
        path: '/LadderControl',  // 梯控管理
        name: 'LadderControl',
        meta: { title: translate('设备.梯控管理') },
        component: () => import('../views/pages/LadderControl/index')
      },
      {
        path: '/PowerSwitch',  // 取电开关
        name: 'PowerSwitch',
        meta: { title: translate('设备.取电开关') },
        component: () => import('../views/pages/PowerSwitch/index')
      },
      // 开锁权限
      {
        path: '/Card',  // 卡
        name: 'Card',
        meta: { title: translate('开锁.卡') },
        component: () => import('../views/pages/Card/index')
      },
      {
        path: '/BluetoothKey',  // 蓝牙钥匙
        name: 'BluetoothKey',
        meta: { title: translate('开锁.蓝牙钥匙') },
        component: () => import('../views/pages/BluetoothKey/index')
      },
      { 
        path: '/Password',  // 密码
        name: 'Password',
        meta: { title: translate('开锁.密码') },
        component: () => import('../views/pages/Password/index')
      },
      { 
        path: '/QRCode',   // 二维码
        name: 'QRCode',
        meta: { title: translate('开锁.二维码') },
        component: () => import('../views/pages/QRCode/index')
      },
      // 入住信息
      { 
        path: '/CheckIn',   
        name: 'CheckIn',
        meta: { title: translate('入住.入住记录') },
        component: () => import('../views/pages/CheckIn/index')
      },
       // 任务列表
       { 
        path: '/TaskList',   
        name: 'TaskList',
        meta: { title: translate('任务列表') },
        component: () => import('../views/pages/TaskList/index')
      },
      // 添加页面
      { 
        path: '/addStore',  
        name: 'addStore',
        meta: { title: translate('门店管理.门店列表') },
        component: () => import('../views/routerPage/addStore.vue')
      },
      { 
        path: '/addAdmin',  
        name: 'addAdmin',
        meta: { title: translate('门店管理.员工管理') },
        component: () => import('../views/routerPage/addAdmin.vue')
      },
      {
        path: '/RoomType', // 房间类型
        name: 'RoomType',
        meta: { title: translate('房间.房间类型') },
        component: () => import('../views/routerPage/roomType.vue')
      },
      { 
        path: '/addJob',  
        name: 'addJob',
        meta: { title: translate('门店管理.岗位管理') },
        component: () => import('../views/routerPage/addJob.vue')
      },
      { 
        path: '/addBuilding/:id',  
        name: 'addBuilding',
        meta: { title: translate('楼栋') + '/' + translate('楼层') },
        component: () => import('../views/routerPage/addBuilding.vue')
      },
      { 
        path: '/addRoomType',  
        name: 'addRoomType',
        meta: { title: translate('房间.房间类型') },
        component: () => import('../views/routerPage/addRoomType.vue')
      },
      { 
        path: '/addRoom',  
        name: 'addRoom',
        meta: { title: translate('房间.房间') },
        component: () => import('../views/routerPage/addRoom.vue')
      },
      { 
        path: '/addPublic',  
        name: 'addPublic',
        meta: { title: translate('公共门.普通公共门') },
        component: () => import('../views/routerPage/addPublic.vue')
      },
      { 
        path: '/addElevator',  
        name: 'addElevator',
        meta: { title: translate('公共门.电梯区域') },
        component: () => import('../views/routerPage/addElevator.vue')
      },
      { 
        path: '/addGrouping',  
        name: 'addGrouping',
        meta: { title: translate('公共门.分组管理') },
        component: () => import('../views/routerPage/addGrouping.vue')
      },
      // 短信充值
      { 
        path: '/smsRecharge',  
        name: 'smsRecharge',
        meta: { title: translate('账户.增值服务') },
        component: () => import('../views/routerPage/smsRecharge.vue')
      },
      { 
        path: '/smsRecord',  
        name: 'smsRecord',
        meta: { title: translate('账户.增值服务') },
        component: () => import('../views/routerPage/smsRecord.vue')
      },
      { 
        path: '/smsSending',  
        name: 'smsSending',
        meta: { title: translate('账户.增值服务') },
        component: () => import('../views/routerPage/smsSending.vue')
      },
      // 添加设备
      { 
        path: '/addEquipment',  
        name: 'addEquipment',
        meta: { title: translate('公共门.电梯区域') },
        component: () => import('../views/routerPage/addEquipment.vue')
      },
       // 开锁记录
       { 
        path: '/unlockList',  
        name: 'unlockList.',
        meta: { title: translate('开锁记录') },
        component: () => import('../views/pages/UnlockList/index')
      },
       // 记录
       { 
        path: '/abnormalRecords',  
        name: 'abnormalRecords',
        meta: { title: translate('异常记录') },
        component: () => import('../views/routerPage/abnormalRecords.vue')
      },
      // 立即购买
      { 
        path: '/buyNow',  
        name: 'buyNow',
        meta: { title: '设备列表' },
        component: () => import('../views/routerPage/buyNow.vue')
      }
    ]
  },
]
 

const router = new VueRouter({
  mode:'history',
  routes
})





router.beforeEach((to, from, next) => {
  const token = store.state.userInfo.token
  const tabs = store.state.tabs.tabs

  // router.addRoute( { path: '/DashBoard', name: 'DashBoard',  component: () =>  import('../views/pages/basicInformationt/index')}) 
  // console.log(router);
  // 在跳转前执行逻辑
  if(to.path === '/login') {
      const locaies = navigator.language
      const local = store.state.locales.local
      if(!local) {
        const l =  locaies === 'zh-CN' ? 'zh': 'en'  // 检测有没有本地语言配置
        store.commit('locales/setlocal',  l)
      }
      if(token) {
          next('/basicInformationt')
      }
  }

  if(!token && to.path !== '/login') {  // 没有token返回登录页
       
      next('/login')
  }else {
  
    if(tabs.length == 0 && to.meta.title) {  // 阻止tabs栏出现空白栏
      
      store.commit('tabs/chanegRouter', { title: to.meta.title, path: to.path })
      next()
    }else if( to.path != '/login' && to.path != '/' ) {
      store.commit('tabs/chanegRouter', { title: to.meta.title, path: to.path })
    
      next(); // 继续跳转
    }else {
     
      next(); // 继续跳转
    }

    
  }
  
});

router.afterEach(() => {
  // 在跳转后执行逻辑
  // console.log('已完成路由跳转');
});


export default router
