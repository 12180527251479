<template>
   <div class="box">
        <div class="header">
           <div class="header-title">
            <slot name="header"></slot>
           </div>
        </div>
        <div class="main">
             <slot name="main"></slot>
        </div>
   </div>
</template>
<script>
export default {
   name: 'MyCard'
};
</script>
<style lang='scss' scoped>
.box {
   width: 100%;
   // height: 77vh;
   // height: auto !important;
   min-height: 77vh;
   height: 77vh; 
  
}
.header {
      background-color: #fff;
      padding: 10px 15px 0 20px;
   }
   .header-title {
     height: 50px;
     line-height: 40px;
     font-weight: 550;
     font-size: 14px;
}
   .main {
     box-sizing: border-box;
     margin-top: 15px;
     margin-right: 15px;
     background-color: #fff;
     width: 100%;
     height: 100%;
     padding: 20px 15px 0 20px;
      overflow: auto;
   }
   .primary {
      background-color: #FD8137 ;
   }
</style>